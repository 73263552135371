function AppUtilities()
{
    this.user_is_logged = function()
    {
        let _session_token = appUtilities.getParam('session-token');

        if(_session_token == null)
        {
            return false;
        }
        else
        {
            return (_session_token != "");
        }
    }

    this.setParam = function(name, value)
    {
        window.localStorage.setItem(name, value);
    }

    this.getParam = function(name)
    {
        return window.localStorage.getItem(name);
    }

    this.removeParam = function(name)
    {
        window.localStorage.removeItem(name);
    }

    this.validateEmail = function(email) 
    {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return re.test(email);
    }

    this.convertLocalDateToUTC = function(dateString) 
    { 
        // Converte da LOCALE (IT) a UTC

        let _ret = new moment(dateString).utc();

        console.log('local to utc', _ret);

        return _ret; 
    }

    this.convertUTCToLocalDate = function(dateString) 
    { 
        // Converte da UTC a LOCALE (IT)
        // NB: La data in input deve essere in formato UTC!

        let _ret = new moment(dateString + "Z");

        console.log('utc to local', _ret);

        return _ret; 
    }
}

export default AppUtilities;
