
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageAfterIn: function () {
      var self = this;
      var app = self.$app;
      console.log('routes /app-info/');
      self.$setState({
        data: {
          'app_version': app_version
        }
      });
    }
  },
  id: 'd8eaa637aa',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=app-info><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Indietro</span></a></div><div class=title>Info app</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><div class="card-content card-content-padding"><div class="list media-list"><ul><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title>App version</div><div class=item-after>';
          r += c(ctx_2.data.app_version, ctx_2);
          r += '</div></div><div class=item-subtitle></div></div></div></li></ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    