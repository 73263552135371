function AppCore(initCallback)
{
    let self = this;

    this.session_token = '';

    if(typeof initCallback !== 'undefined')
    {
        window.appCore = self;
        
        initCallback();
    }

    this.financial = function(value)
    {
        return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
    }
}

export default AppCore;
