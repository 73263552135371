import $$ from 'dom7';
import Template7 from 'template7';

import NotFoundPage from '../pages/404.f7.html';

import HelpPage from '../pages/help.f7.html';
import AppInfoPage from '../pages/app-info.f7.html';
import TermsPage from '../pages/terms.f7.html';
import AvailableOrdersPage from '../pages/available-orders.f7.html';
import AcceptedOrdersPage from '../pages/accepted-orders.f7.html';
import OrderDetailsPage from '../pages/order-details.f7.html';
import LoginPage from '../pages/login.f7.html';
import SignUpPage from '../pages/signup.f7.html';
import ConfirmProfilePage from '../pages/confirm-profile.f7.html';
import ProfilePage from '../pages/profile.f7.html';

var routes = 
[
  /*
  {
    path: '(.*)',
    component: NotFoundPage,
  },
  */

  // /index.html
  {
    path: '/index.html',
    component: LoginPage,
    on: 
    {
      pageInit: function (event, page) 
      {
        ViewLogin = f7app.views.get('#view-login');
        ViewAvailableOrders = f7app.views.get('#view-available-orders');
        ViewAcceptedOrders = f7app.views.get('#view-accepted-orders');
        ViewProfile = f7app.views.get('#view-profile');

        let _user_logged = appUtilities.user_is_logged();

        if(_user_logged == true)
        {
          $$('.toolbar').show();

          f7app.tab.show('#view-available-orders', '#tab-available-orders', true);
        }
      },
      pageAfterIn: function (event, page) 
      {
      },
    }
  },

  // /
  {
    path: '/',
    component: LoginPage,
    on: 
    {
      pageInit: function (event, page) 
      {
        ViewLogin = f7app.views.get('#view-login');
        ViewAvailableOrders = f7app.views.get('#view-available-orders');
        ViewAcceptedOrders = f7app.views.get('#view-accepted-orders');
        ViewProfile = f7app.views.get('#view-profile');

        let _user_logged = appUtilities.user_is_logged();

        if(_user_logged == true)
        {
          $$('.toolbar').show();

          f7app.tab.show('#view-available-orders', '#tab-available-orders', true);
        }
      },
      pageAfterIn: function (event, page) 
      {
      },
    }
  },

  // /help/
  {
    path: '/help/',
    component: HelpPage,
  },

  // /app-info/
	{
		path: '/app-info/',
		component: AppInfoPage,
  },
  
  // /terms/
  {
    path: '/terms/',
    component: TermsPage,
  },

  // /login/
  {
    path: '/login/',
    component: LoginPage,
  },

  // /signup/
  {
    path: '/signup/',
    component: SignUpPage,
  },

  // /confirm-profile/
  {
    path: '/confirm-profile/',
    component: ConfirmProfilePage
  },
  
  // /profile/
  {
    path: '/profile/',
    component: ProfilePage
  },

  // /address/
  {
    path: '/address/',
    async: function (routeTo, routeFrom, resolve, reject) 
    {
      var router = this;
      var app = router.app;

      var param = routeTo.params.param;

      var _request_params = 
      {
        'session_token' : appCore.session_token
      };

      //app.dialog.preloader(); //Preloader

      app.request(
      {
        url: api_url + 'customer_address_listing.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (data) 
        {
          //app.dialog.close(); //close preloader

          console.log(data);

          let _success = parseFloat(data.success);

          if(_success == 0)
          {
            // Data not found

            resolve(
            {
              component: AddressPage,
            },
            {
              context: 
              {
                data: 
                {         
                },
              }
            });
          }
          else
          {
            // Data found

            resolve(
            {
              component: AddressPage,
            }, 
            {
              context: 
              {
                data: data,
              }
            });
          }
        },
        error: function () 
        {
          //app.dialog.close();

          app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
          
          reject();
        }
      });
    },
    on: 
    {
      pageInit: function (event, page) 
      {
        mapManager.Init_Map();
        mapManager.Init_AutoComplete();

        switch(appCore.selection_address_type)
        {
          case 'current-location':

            $$('#write-address-section').css('display', 'none');
            $$('#current-position-section').css('display', 'block');
            $$('#recents-address-section').css('display', 'none');

            $$('#confirm-current-location').css('display', 'none');

            mapManager.Get_Current_Location();

            break;

          case 'insert-address':

            $$('#write-address-section').css('display', 'block');
            $$('#current-position-section').css('display', 'none');
            $$('#recents-address-section').css('display', 'none');

            $$('#confirm-current-location').css('display', 'none');

            break;

          case 'recents-address':

            $$('#write-address-section').css('display', 'none');
            $$('#current-position-section').css('display', 'none');
            $$('#recents-address-section').css('display', 'block');

            $$('#confirm-current-location').css('display', 'none');

            break;    
            
          default:

            if(appCore.first_address_selection == true)
            {
              $$('#address-back').css('display', 'none');
            }

            break;
        }
      },
      pageAfterIn: function (event, page) 
      {
      }
    }
  },

  // /available-orders/
  {
    path: '/available-orders/',
    component: AvailableOrdersPage,
  },

  // /accepted-orders/
  {
		path: '/accepted-orders/',
		component: AcceptedOrdersPage,
  },

  // /order-details/
	{
		path: '/order-details/',
		component: OrderDetailsPage,
	},
];

function show_toolbar(animate)
{
  if(typeof callback === 'undefined')
  {
    animate = false;
  }

  f7app.toolbar.show('.toolbar', animate);
}

function hide_toolbar(animate)
{
  if(typeof callback === 'undefined')
  {
    animate = false;
  }
  
  f7app.toolbar.hide('.toolbar', animate);
}

export default routes;