export default {
  id: '789f04eaf8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><div class="page-content login-screen-content"><div class=login-screen-title><img src=./static/images/jacknb_logo.png style="max-width: 40%;"></div><form><div class=list><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Codice temporaneo di 6 cifre ricevuto per messaggio</b></div><div class=item-input-wrap><input id=confirm-account-otp type=number max=999999 name=confirm-account-otp placeholder="Inserisci qui il codice" required validate></div></div></li></ul></div><div class=block><p class=row><button id=confirm-profile class="col button button-outline button-raised">Conferma</button></p></div></form><div class=block><p class=row><a href=# class="link back">Indietro</a></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};